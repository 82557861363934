import React, { useEffect, useState, useRef } from "react";
import Sidebar from "./sidebar";
import LiveChat from "./livechat";
import ToggleIcon from "../assets/toggle.svg";
import NewChatIcon from "../assets/newChat.svg";
import FeedbackModal from "./feedbackModal";
import Logo from "../assets/Logo.svg";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FAQ from "./faq";
import {
  getAllChats,
  getAllMessages,
  setIntroFlag,
} from "../services/chatService";

function ConversationPage() {
  const [toggle, setToggle] = useState(true);
  const [selectedChatMessages, setSelectedChatMessages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chats, setChats] = useState([]);
  const [newChat, setNewChat] = useState(false);
  const [selectedChatId, setSelectedChatId] = useState(null);
  const userId = localStorage.getItem("userId");
  const [initials, setInitials] = useState("");
  const userName = localStorage.getItem("name");
  const [feedback, setFeedback] = useState("");
  const [loading, setLoading] = useState(true);
  const sidebarRef = useRef(null);
  const [displayFAQ, setDisplayFAQ] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState({
    id: "",
    type: "",
  });
  const [timeZone, setTimeZone] = useState(() => {
    const storedTimezone = localStorage.getItem('timezone');
    if (storedTimezone) {
      return JSON.parse(storedTimezone);
    }
    return { value: 'America/New_York', label: 'America/New_York' };
  });

  //Information toast at every render
  useEffect(() => {
    toast.info("Responses may be inaccurate; don't rely on them completely.", {
      position: "top-right",
      autoClose: 5000,
      closeOnClick: true,
      hideProgressBar: false,
      transition: Bounce,
      className: "text-left mt-[70px] ml-4 md:mt-0 md:ml-0",
      style: { fontFamily: "Popins", color: "rgba(102 102 102)" },
    });
    // eslint-disable-next-line
  }, []);

  //Close Sidebar on clicking outside the sidebar for mobile screens
  const handleClickOutside = (event) => {
    if (
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target) &&
      window.innerWidth < 768
    ) {
      setToggle(false);
    }
  };

  //handles the click outside the sidebar to set toggle to false to close sidebar for small screens
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);

  //get Initial for full user name
  const trimName = () => {
    const nameParts = userName.split(" ");
    const firstInitial = nameParts[0] ? nameParts[0][0] : "";
    const lastInitial =
      nameParts.length > 1 ? nameParts[nameParts.length - 1][0] : "";
    const initialname = firstInitial + lastInitial;
    setInitials(initialname);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setToggle(false);
      } else {
        setToggle(true);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
        // eslint-disable-next-line
  }, []);

  // By default select the latest chat and fetch messages to display
  useEffect(() => {
    if (!selectedChatId && chats.length > 0) {
      setSelectedChatId(chats[chats.length - 1]._id);
      handleSelectChat(chats[chats.length - 1]._id);
    }
        // eslint-disable-next-line
  }, [chats]);

  // Fetch all the chats to display in sidebar.
  useEffect(() => {
    fetchAllChats();
    trimName();
        // eslint-disable-next-line
  }, []);


  

  // Method to fetch all the chats for logged in user
  const fetchAllChats = async () => {
    try {
      const allChats = await getAllChats(userId);
      setChats(allChats.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  //set intro flag
  const handleSetIntroFlag = async (chat) => {
    if (chat.introFlag === false) {
      try {
        await setIntroFlag(chat._id);
      } catch (error) {
        console.log("Error updating the Intro Flag:", error);
      }
    }
  };

  // Method to fetch all the messages for a particular chat ID
  const fecthAllMessages = async (id) => {
    try {
      const response = await getAllMessages(id);
      setSelectedChatMessages(response.data);
    } catch (error) {
      console.log("Error Fetching Messages: ", error);
    }
  };

  // Method to fetch all the messages for selected chat in sidebar
  const handleSelectChat = (id) => {
    fecthAllMessages(id);
  };


  const handleToggle = () => {
    setToggle(!toggle);
  };

  const handleNewChat = () => {
    if (!newChat) {
      setNewChat(true);
      setSelectedChatId(null);
      if(toggle && window.innerWidth < 768 )
      {
        handleToggle();
      }
    }
  };



 
  return (
    <div className="relative z-0 w-full max-h-[100vh] flex flex-col md:flex-row">
      {isModalOpen ? (
        <FeedbackModal
          setIsModalOpen={setIsModalOpen}
          setFeedback={setFeedback}
          feedback={feedback}
          selectedFeedback={selectedFeedback}
        />
      ) : (
        <div
          className={`relative w-full max-h-[100vh] flex overflow-hidden 
        
        `}
        >
          {/* Sidebar toggle and buttons */}
          <div
            className={`w-full md:w-fit gap-4  items-start   p-[20px] md:p-[10px] fixed  top-0 md:relative  z-10 md:z-20 h-[60px]  shadow-lg md:shadow-none bg-[#F9F9F9] md:bg-white
            ${
              toggle
                ? "hidden"
                : "flex justify-between md:justify-center  items-center"
            }
            `}
          >
            <button
              onClick={handleToggle}
              className="p-[2px] hover:bg-[#e6e5e5] hover:rounded-md"
            >
              <img
                src={ToggleIcon}
                style={{ maxWidth: "none" }}
                alt="Frame Icon"
                className="w-6 h-6 "
              />
            </button>
            <div className="flex justify-center items-center gap-2">
              <img
                src={Logo}
                alt="SportGPT Logo"
                className="w-8 h-8 flex md:hidden "
              />
              <span className=" flex md:hidden font-Popins text-[24px] md:text-[32px] font-[700]  ">
                Sports
              </span>
            </div>
            <button
              onClick={handleNewChat}
              className="p-[2px] hover:bg-[#e6e5e5] hover:rounded-md"
            >
              <img
                src={NewChatIcon}
                alt="Frame Icon"
                style={{ maxWidth: "none" }}
                className="w-6 h-6 "
              />
            </button>
          </div>

          <div className={`${displayFAQ?"hidden":" flex"}`}>
          <ToastContainer />
          </div>
          {/* Sidebar component with z-index applied */}

          {toggle && (
            <div
              ref={sidebarRef}
              className="absolute md:relative z-20 w-fit md:w-auto h-full top-0 left-0 md:z-10"
            >
              <Sidebar
                toggle={toggle}
                setToggle={setToggle}
                chats={chats}
                setNewChat={setNewChat}
                newChat={newChat}
                onSelectChat={handleSelectChat}
                setSelectedChatId={setSelectedChatId}
                selectedChatId={selectedChatId}
                initials={initials}
                userName={userName}
                handleNewChat={handleNewChat}
                updateChats={fetchAllChats}
                setDisplayFAQ={setDisplayFAQ}
                displayFAQ={displayFAQ}
                setTimeZone={setTimeZone}
                timeZone={timeZone}
              />
            </div>
          )}

          {/* LiveChat component */}
          <div
            className={` relative z-0 ${
              displayFAQ ? "hidden" : "flex flex-1"
            } `}
          >
            {loading ? (
              ""
            ) : (
              <LiveChat
                chat={!newChat ? selectedChatMessages : null}
                setNewChat={setNewChat}
                newChat={newChat}
                setIsModalOpen={setIsModalOpen}
                updateMessages={fecthAllMessages}
                updateChats={fetchAllChats}
                setSelectedChatId={setSelectedChatId}
                initials={initials}
                userName={userName}
                setSelectedFeedback={setSelectedFeedback}
                toggle={toggle}
                chats={chats}
                setSelectedChatMessages={setSelectedChatMessages}
                selectedChatId={selectedChatId}
                handleSetIntroFlag={handleSetIntroFlag}
                timeZone={timeZone}
              />
            )}
          </div>
          <div className={`relative z-0 ${displayFAQ?"flex flex-1":"hidden"} `}>
            <FAQ/>
          </div>
        </div>
      )}
    </div>
  );
}

export default ConversationPage;
