import React from 'react';
import Select from 'react-select';

const timezoneOptions = [
    { value: 'America/New_York', label: 'America/New_York' },
    { value: 'America/Los_Angeles', label: 'America/Los_Angeles' },
    { value: 'Europe/Berlin', label: 'Europe/Berlin' },
    { value: 'Europe/London', label: 'Europe/London' }
];

const TimezoneSelect = ({timeZone, setTimeZone}) => {
  const handleTimezoneChange = (selectedOption) => {
    // Update the state through the parent component
    setTimeZone(selectedOption);
    
    // Store in localStorage
    localStorage.setItem('timezone', JSON.stringify(selectedOption));
  };

  return (
    <div className="w-[100%]">
      <Select
        defaultValue={timeZone}
        onChange={handleTimezoneChange}
        options={timezoneOptions}
        placeholder={timeZone}
        className="text-sm"
        classNamePrefix="timezone-select"
      />
    </div>
  );
};

export default TimezoneSelect;