import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Logo from "../assets/Logo.svg";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { createnewUser, loginUser } from "../services/userService";
import SocialIcon from "../assets/Social icon.svg";
import { AuthContext } from "../context/authContext";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ShowPasswordIcon from "../assets/lightEye.svg"

function Signup() {
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  //Information toast at every render
  const displayErrorMessage = (message) => {
    toast.info(message, {
      position: "top-right",
      autoClose: 5000,
      closeOnClick: true,
      hideProgressBar: false,
      transition: Bounce,
      className: "text-left mt-[70px] ml-4 md:mt-0 md:ml-0",
      style: { fontFamily: "Popins", color: "rgba(102 102 102)" },
    });
  };

  // Formik setup
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      authProviderFlag: "local",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters long")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/[0-9]/, "Password must contain at least one number")
        .matches(
          /[\W_]/,
          "Password must contain at least one special character"
        )
        .required("Password is required"),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setIsLoading(true);
      try {
        const res = await createnewUser(values);
        console.log("New User: ", res);
        displayErrorMessage(res.message);
        resetForm();

      } catch (error) {
        console.log("Hello World", error.response.data.message);
        displayErrorMessage(error.response.data.message);
        resetForm();
      } finally {
        setSubmitting(false);
        setIsLoading(false);
      }
    },
  });

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const { access_token } = tokenResponse;

      if (access_token) {
        try {
          const response = await fetch(
            "https://www.googleapis.com/oauth2/v3/userinfo",
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            }
          );
          const userInfo = await response.json();

          const newUser = {
            name: userInfo.name,
            email: userInfo.email,
            password: "",
            authProviderFlag: "google",
          };

          await createnewUser(newUser);
          const userData = {
            email: userInfo.email,
            password: "", // Password is typically empty or not needed when using OAuth
            authProviderFlag: "google",
          };

          const loginResponse = await loginUser(userData);
          login(loginResponse);
          navigate("/chat");
        } catch (error) {
          displayErrorMessage(error.response.data.message);
        }
      } else {
        console.log("Access token not found");
      }
    },
    onError: () => {
      displayErrorMessage("Login Failed try again later");
    },
  });
  return (
    <div className="w-full min-h-full  flex justify-center items-center py-[40px] ">
      <ToastContainer />
      <div className="flex flex-col w-[90%]  xsm:w-[400px] md:w-[500px] gap-[20px] md:gap-[32px] py-[40px] md:py-[60px]  px-[40px] md:px-[60px] shadow-2xl bg-white rounded-[32px]">
        <div className="flex justify-center items-center">
          <img src={Logo} alt="App Logo" className="w-[89.6px] h-[80px]" />
        </div>

        <div className="flex flex-col gap-[12px]">
          <h2 className="flex justify-center font-Popins text-[24px] md:text-[30px] leading-[32px] md:leading-[38px] font-[700] text-center text-[#010101]">
            Sign up
          </h2>
          <p className="flex justify-center font-Popins font-[400] text-[14px] md:text-[16px] leading-[22px] md:leading-[24px] text-center text-[#667085]">
            Create your account
          </p>
        </div>


        <form
          onSubmit={formik.handleSubmit}
          className="flex w-full flex-col gap-[20px]"
        >
          <div className="flex flex-col gap-[6px]">
            <label
              className="flex text-[#344054] font-Popins text-[14px] not-italic font-[500] leading-[20px]"
              htmlFor="name"
            >
              Name
            </label>
            <input
              className={`flex w-full py-[10px] px-[14px] gap-[8px] rounded-[8px] border-[1px] ${
                formik.touched.name && formik.errors.name
                  ? "border-red-500"
                  : "border-[#D0D5DD]"
              }`}
              id="name"
              name="name"
              type="text"
              placeholder="Full Name"
              {...formik.getFieldProps("name")}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="text-red-500 text-[12px] flex ">
                {formik.errors.name}
              </div>
            ) : null}
          </div>

          <div className="flex flex-col gap-[6px]">
            <label
              className="flex text-[#344054] font-Popins text-[14px] not-italic font-[500] leading-[20px]"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className={`flex w-full py-[10px] px-[14px] gap-[8px] rounded-[8px] border-[1px] ${
                formik.touched.email && formik.errors.email
                  ? "border-red-500"
                  : "border-[#D0D5DD]"
              }`}
              id="email"
              name="email"
              type="email"
              placeholder="Enter your email"
              {...formik.getFieldProps("email")}
            />

            {formik.touched.email && formik.errors.email ? (
              <div className="text-red-500 text-[12px] flex">
                {formik.errors.email}
              </div>
            ) : null}
          </div>

          <div className="relative flex flex-col gap-[6px]">
            <label
              className="flex text-[#344054] font-Popins text-[14px] not-italic font-[500] leading-[20px]"
              htmlFor="password"
            >
              Password
            </label>
            <input
              className={` flex w-full py-[10px] px-[14px] gap-[8px] rounded-[8px] border-[1px] ${
                formik.touched.password && formik.errors.password
                  ? "border-red-500"
                  : "border-[#D0D5DD]"
              }`}
              id="password"
              name="password"
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              {...formik.getFieldProps("password")}
            />
            
            {formik.touched.password && formik.errors.password ? (
              <div className="text-red-500 text-[12px] flex">
                {formik.errors.password}
              </div>
            ) : null}
            <button
            onClick={()=>setShowPassword(!showPassword)} 
            type="button"
            className="absolute top-10 right-2 " >
              <img src={ShowPasswordIcon} alt="Show Password" />
            </button>
          </div>

          <div className="flex w-full">
            <button
              type="submit"
              disabled={formik.isSubmitting}
              className="flex w-full justify-center rounded-[8px] py-[10px] px-[18px] bg-[#E61F26] border-[1px] border-[#E61F26] font-Popins text-[16px] font-[600] leading-[24px] text-white"
            >
              {isLoading ? "Signing up..." : "Sign up"}
            </button>
          </div>
        </form>

        <div className="flex flex-col items-center w-full gap-[16px]">
          <div className="flex w-full rounded-[8px] border-[1px] border-[#D0D5DD]">
            <button
              onClick={() => handleGoogleLogin()}
              className="w-full flex items-center justify-center gap-[12px] py-[10px] px-[16px] rounded-[8px] hover:bg-[#D0D5DD] "
            >
              <img src={SocialIcon} alt="Google Social Icon" />
              <span className="text-[#344054] font-Popins text-[16px] font-[600] leading-[24px]">
                Sign up with Google
              </span>
            </button>
          </div>
        </div>

        <div className="flex w-full justify-center items-center gap-[4px]">
          <span className="text-[#667085] font-Popins text-[14px] font-[400] leading-[20px]">
            Already have an account?
          </span>
          <button
            onClick={() => navigate("/")}
            className="flex text-[#E61F26] bg-transparent font-Popins text-[14px] font-[600] leading-[20px]"
          >
            Sign in
          </button>
        </div>
      </div>
    </div>
  );
}

export default Signup;
