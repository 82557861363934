import axios from "axios";


const BASE_URL = 'https://api.protvguide.com/api/chat';
// const BASE_URL = 'http://localhost:5005/api/chat';

export const createNewChat = async (newChat) => {
    try {
      const response = await axios.post(`${BASE_URL}/post-chat`, newChat);
      return response.data;
    } catch (error) {
      console.error('Error Posting Chat: ', error);
      throw error;
    }
  };

  export const getAllChats = async (userId) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/all-chats/${userId}`
      );
      return response;
    } catch (error) {
      console.error('Error fetching all chats: ', error);
      throw error;
    }
  };

  export const getAllMessages = async (chatId) => {
    try {
      const response = await axios.get(`${BASE_URL}/get-chat/${chatId}`)
      return response;
    } catch (error) {
      console.error('Error fetching selected chat messages: ', error);
      throw error;
    }
  };

  export const updateAIResponse = async (chatId, message)=>{
    try {
     await axios.put(`${BASE_URL}/update-assistant-message/${chatId}`, message);
     } catch (error) {
       console.error('Error Updating AI response message: ', error);
       throw error;
     }
  }

  export const updateIntroductionMessage = async (messageId, message)=>{
    try {
     await axios.put(`${BASE_URL}/update-message-text/${messageId}`, message);
     } catch (error) {
       console.error('Error updating introduction message: ', error);
       throw error;
     }
  }

  export const setIntroFlag = async (chatId)=>{
    try {
    await axios.put(`${BASE_URL}/introMessage/${chatId}`)
     } catch (error) {
       console.error('Error updating introduction flag: ', error);
       throw error;
     }
  }


  export const postNewMessage = async (chatId, message) => {
    try {
     await axios.post(`${BASE_URL}/post-message/${chatId}/messages`, message);
    } catch (error) {
      console.error('Error Posting Message: ', error);
      throw error;
    }
  };


  export const getAIResponse = async (payload) => {
    try {
      const response = await axios.post('https://ai.protvguide.com/query_response', payload, {
        timeout: 300000 // 2 minutes = 120 seconds = 120,000 milliseconds
      });
      return response.data;
    } catch (error) {
      console.error('Error Fetching Response From AI Bot: ', error);
      throw error;
    }
  };

  export const deleteSelectedChat = async (chatId) => {
    try {
      await axios.delete(`${BASE_URL}/delete-chat/${chatId}`);
    } catch (error) {
      console.error('Error deleting selected chat ', error);
      throw error;
    }
  };

